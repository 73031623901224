import React, { useEffect } from "react";
import useSetPageData from "../../utils/useSetPageData";
import ComponentHeader from "../../components/panels/ComponentHeader";
import Hero from "../../components/panels/Hero";
import Switch from "../../components/panels/Switch";
import PanelFoundation from "../../components/panels/PanelFoundation";
import SEO from "../../components/seo";

// image
import texasImage from "../../images/Texas.png";
import texasDirImage1 from "../../images/texas_dir_11.jpg";
import texasDirImage2 from "../../images/texas_dir_22.jpg";
import texasDirImage3 from "../../images/texas_dir_33.jpg";
// import texasDirImage1New from "../../images/texas_dir_1_new.jpg";
// import texasDirImage2New from "../../images/texas_dir_2_new.jpg";
// import texasDirImage3New from "../../images/texas_dir_3_new.jpg";
import DIRWhiteLogo from "../../images/DIR_logo_Name_White.png";
// import DIRTempImage from "../../images/DIR_temp_image.png";
import DIRHeader from "../../images/dir_cover_image.jpg";

// styles
import s from "./1.module.scss";
// import autoprefixer from "autoprefixer";

const texasDirPage = ({ location, data }) => {
  //   const { SEO: seo, hero } = data.strapiPContact;

  useSetPageData(location);

  useEffect(() => {
    // This will make the page unsearchable by all engine crawlers according to google.
    // Ref: https://developers.google.com/search/docs/advanced/crawling/block-indexing

    const noIndex = document.createElement("meta");
    noIndex.setAttribute("name", "robots");
    noIndex.setAttribute("content", "noindex");
    document.head.append(noIndex);

    let styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.innerHTML = `#panelFoundationBg:before {background: url(${DIRHeader}); background-position: center;}`;

    return () => {
      document.head.removeChild(noIndex);
    };
  }, []);

  return (
    <>
      <Hero
        heading={<img src={DIRWhiteLogo} style={{ width: "120px" }} />}
        iconComponent={<img src={texasImage} style={{ width: "100px" }} />}
      // icon={hero[0].contact_hero_image.childImageSharp.fixed}
      />
      <SEO
        {...{
          //   title: `${hero[0].main_heading}`,
          title: `Texas DIR`
          // description: seo.description
        }}
      />
      <Switch
        // panelPadding={false}
        // textColStyles={{ paddingTop: "10%" }}
        contents={[
          {
            image: <img src={texasDirImage1} style={{ width: "100%" }} />,
            heading: "NITCO Inc. DIR Software, Commercial Off-the-Shelf (COTS) and Related Services",
            content: (
              <>
                <p>
                  NITCO Inc., offers on-prem software and Cloud based digital
                  automation products as a solution partner reseller. In
                  addition, NITCO offers installation, configuration,
                  maintenance, support, architectural assessment and design,
                  business and technical services and training services
                  focusing on RPA (Robotic Process Automation) and AI
                  (Artificial Intelligence) automation solutions available under
                  this contract.
                  <br />
                  <br />
                  This NITCO Inc., DIR cooperative contract may be used by state
                  and local government, public education, other public entities
                  in Texas, as well as public entities outside the state based
                  on{" "}
                  <strong>
                    <a href="https://dir.texas.gov/it-solutions-and-services/customer-eligibility">
                      DIR Customer Eligibility guidelines.
                    </a>
                  </strong>
                </p>
                <br />
                <p>
                  <strong>
                    DIR Contract:{" "}
                    <a href="https://dir.texas.gov/contracts/dir-cpo-5352">
                      DIR-CPO-5352 Software Solutions
                    </a>
                  </strong>
                </p>
              </>
            )
          },

          {
            image: <img src={texasDirImage2} style={{ width: "100%" }} />,

            heading: <span>DIR Software and Commercial Off-the-Shelf (COTS)</span>,
            content: (
              <>
                <p>
                  <h4>
                    <a href="https://kore.ai/" className={s.link}>
                      Kore.ai
                    </a>
                  </h4>
                  Kore.ai is an AI Experience Optimization Platform that empowers businesses with a suite of tools to create, deploy, and manage AI-powered chatbots and virtual assistants. Leveraging advanced natural language processing (NLP) capabilities, Kore.ai delivers personalized and engaging customer experiences across various channels.
                  <br />
                  <br />
                  <br />
                </p>
                <p>
                  <h4>
                    <a href="https://openbots.ai/" className={s.link}>
                      OpenBots
                    </a>
                  </h4>
                  OpenBots is a automation platform that combines the power of robotic process automation (RPA) with intelligent automation.
                  <br />
                  <br />
                  <br />
                </p>
              </>
            )
          },
          {
            image: <img src={texasDirImage3} style={{ width: "100%" }} />,

            heading: "DIR Software Solutions Related Services",
            content: (
              <>
                <p>
                  NITCO, a State of Texas approved HUB vendor, provides the
                  following related services to compliment the
                  software solutions in the cooperative contract:
                </p>
                <ul>
                  <li>Configuration</li>
                  <li>Implementation</li>
                  <li>Managed Services</li>
                  <li>Technical Training</li>
                  <li>Analysis</li>
                  <li>End User Training</li>
                  <li>Project Management</li>
                  <li>Automation Maintenance and Support</li>
                </ul>
                <strong>
                  <br />
                  How To Order
                  <br />
                  <br />
                  {/* <a href="https://dir.texas.gov/it-solutions-and-services/buying-through-dir?id=11">
                    Read Information for Buying Through DIR
                  </a> */}
                  <strong>
                    Contract:{" "}
                    <a href="https://dir.texas.gov/contracts/dir-cpo-5352">
                      DIR-CPO-5352
                    </a>
                  </strong>
                </strong>
                <br />
                {/* <br /> */}
                <div className={"col-md-30 col-sm-12 pt-5 pb-5"}>
                  <ol>
                    <li>
                      All available products and related services are listed in
                      the
                      <strong>
                        {" "}
                        <a href="/uploads/DIR-CPO-5352_Appendix_C_Pricing_Index.pdf">
                          Products and Services Listing.
                        </a>
                      </strong>
                    </li>
                    <li>
                      For Quotes, Purchase Orders and Warranty Information,
                      contact Lance Shealy via email at{" "}
                      <a href="mailto:gov@nitcoinc.com">Gov@NitcoInc.com.</a>{" "}
                      Provide your full name, job title, organization name,
                      phone number and email address.
                    </li>
                    <li>
                      NITCO Inc. will respond with a Quote and/or SOW to sell
                      the software or perform the services available under the
                      cooperative contract.
                    </li>
                    <li>
                      Generate a Purchase Order, made payable to NITCO Inc.,
                      Inc. You must reference the DIR contract number for the
                      services requested on your Purchase Order.
                    </li>
                    <li>
                      Send your Purchase Order and quote form to Nancy
                      Herrington at{" "}
                      <a href="mailto:gov@nitcoinc.com">Gov@NitcoInc.com.</a>
                    </li>
                  </ol>
                  <br />
                  <p></p>
                  <p>
                    <strong>
                      <a href="/uploads/DIR-CPO-5352_Appendix_B_HUB_Subcontracting_Plan.pdf">
                        HUB Subcontracting Plan
                      </a>
                    </strong>{" "}
                    (All work performed by NITCO Inc.)
                  </p>
                  <p>
                    <strong>
                      <a href="https://dir.texas.gov/cooperative-contracts?id=41">
                        DIR Cooperative Contract Program Information
                      </a>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <a href="/uploads/Introduction-to-NITCO-Your-Digital-Technology-Partner-v2024.pdf">
                        Introduction to NITCO Your Digital Technology Partner
                      </a>
                    </strong>
                  </p>
                </div>
                <div className={"col-md-12  col-sm-12 pt-5 pb-5"}>
                  <p>
                    <strong>Warranty and Return Policies</strong>
                    <br />
                    <br />
                    For services provided directly by NITCO Inc., Customers may
                    provide written notice to Successful Respondent of errors,
                    inaccuracies, or other deficiencies in products or services
                    provided by Successful Respondent under a Purchase Order
                    within thirty (30) calendar days or receipt of an invoice
                    for such products or services. Successful Respondent shall
                    correct such error, inaccuracy, or other deficiency at no
                    additional cost to Customer. Due to the type of services
                    provided, NITCO Inc. does not offer a Return policy.
                  </p>
                </div>
              </>
            )
          }
        ]}
      />
      <ComponentHeader
        text={"Contract Contact Information"}
        rootClassName={`bg-light`}
      />
      <PanelFoundation
        rootClassName={`bg-light`}
        rowStyle={{ marginBottom: "4rem" }}
      >
        <div className={"col-md-4 offset-md-2 col-sm-12 pt-5 pb-5"}>
          <strong>NITCO Contact</strong>
          <br />
          <p>
            <ul>
              <br />
              <li>Lance Shealy, Sales Director</li>
              <li>Phone: (281) 503-7002 or (713) 412-5055 (Direct)</li>
              <li>
                E-Mail: <a href="mailto:gov@nitcoinc.com">Gov@NitcoInc.com</a>
              </li>
              <li>
                NITCO Inc., 440 Cobia Drive, Suite 1701, Katy, Texas 77494
              </li>
            </ul>
          </p>
          <br />
          <p>
            <strong>State of Texas Vendor ID:</strong> 1263559048700
            <br />
            <strong>
              State of Texas Historically Underutilized Business (HUB),
              Certificate:
            </strong>{" "}
            #HS15818
            <br />
          </p>
        </div>
        <div className={"col-md-6 col-sm-12 pt-5 pb-5"}>
          <strong>Texas DIR Contact</strong>
          <br />
          <p>
            <ul>
              <br />
              <li>Jeremiah Rodriguez CTCD/CTCM</li>
              <li>Phone: (737) 354-7016</li>
              <li>
                E-Mail:{" "}
                <a href="mailto:Jeremiah.Rodriguez@dir.texas.gov">
                  Jeremiah.Rodriguez@dir.texas.gov
                </a>
              </li>
            </ul>
          </p>
        </div>
      </PanelFoundation>
    </>
  );
};

export default texasDirPage;
